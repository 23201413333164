import Vue, { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Unicon from 'vue-unicons';
import axios from 'axios'
import VueAxios from 'vue-axios'
import { uniAngleLeft, uniAngleRight, uniPlay, uniPause } from 'vue-unicons/dist/icons'

Unicon.add([uniAngleLeft, uniAngleRight, uniPlay, uniPause])


createApp(App)
    .use(router)
    .use(Unicon)
    .use(VueAxios, axios)
    .mount('#app')
